

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";


export default defineComponent({
  name: "user-list",
  components: {
  },
  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Title', 'LoginName', 'Type', 'WorkType'],
      filterFieldName: ['Hesap Adı', 'Login', 'Hesap Türü', 'Ödeme Türü'],

    }
  },
  props: {
    widgetClasses: String,
    tableData: Object,
  },
  watch: {

  },
  computed: {
    filteredList: function () {
      if (this.tableData !== undefined){
        return this.tableData.filter(listItem =>
            (listItem.Title !== undefined && !(listItem.Title).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (listItem.LoginName !== undefined && !(listItem.LoginName).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (listItem.Type !== undefined && !(listItem.Type).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (listItem.WorkType !== undefined && !(listItem.WorkType).toLowerCase().indexOf(this.searchName.toLowerCase()))
        );
      }else{
        return [];
      }
    },


  },
  methods: {

    sortData(attr, data) {
      if (this.sortField == '') {
        this.sortType = "asc";
      } else {
        this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
      }
      this.sortField = attr;

      if (this.sortType == 'asc' || this.sortType == 'desc') {
        this[data].sort((a, b) => {
          let fa = '';
          let fb = '';
          if (typeof (attr) == 'string') {
            if (this.sortType == 'asc') {
              fa = a[attr].toLowerCase();
              fb = b[attr].toLowerCase();
            } else {
              fb = a[attr].toLowerCase();
              fa = b[attr].toLowerCase();
            }
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
          }
        });
      }
    }
  },
  setup(props) {
    const myParams = computed(() => {
      return store.getters.generalParams;
    });

    //console.log(MasterConfig);
    return {
      myParams,
      MasterConfig,
    }
  },
});

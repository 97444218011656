import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-xxl-5 mb-xl-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListHeader1 = _resolveComponent("ListHeader1")!
  const _component_AccountTable = _resolveComponent("AccountTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ListHeader1, {
      key: "header1",
      onSetValue: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHeaderValue($event)))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AccountTable, {
        "widget-classes": "mb-5 mb-xl-8",
        tableData: _ctx.myList.data
      }, null, 8, ["tableData"])
    ])
  ], 64))
}
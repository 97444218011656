
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import AccountTable from "@/components/account/List.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import ListHeader1 from "@/components/common/ListHeader1.vue"
import { ElLoading } from 'element-plus'
//
export default defineComponent({
  name: "account-settings",
  components: {
    AccountTable,
    ListHeader1,
  },
  props:{
    setValue: String,
  },
  data: function () {
    return {
      myLoading: false,
      fullscreenLoading: false,
      loading:{},
    }
  },
  methods: {
    setHeaderValue(e) {
      this.myLoading = true;
      store.dispatch(Actions.ACCOUNT_LIST_ALL, {'Title': e})
          .then(() => {
            this.myList = store.getters.accountList;
            this.myLoading = false;
          });
    },
  },
  watch: {
    myLoading(e){
      if(e==true){
        this.loading =  ElLoading.service({
          lock: true,
          text: 'Loading',
        })
      }else{
        this.loading.close();
      }
    }
  },
  setup(props) {
    let fullscreenLoading: false;
    let loading =  ElLoading.service({
      lock: false,
      text: 'Loading',
    })
    onMounted(() => {
      setCurrentPageBreadcrumbs("Cari Hesaplar Listesi", ["Cari Hesaplar", "Liste"]);
    });

    store.dispatch(Actions.ACCOUNT_LIST_ALL, {'Title': 'A'})
        .then(() => {
          loading.close();
        });
    let myList = computed(() => {
      return store.getters.accountList;
    });

    return {
      myList,
    };
  },
});
